
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide navUser-action navUser-action--quickSearch cm_searchBoxDialogButton' }, _createElement('svg', {
                'width': '20',
                'height': '20',
                'viewBox': '0 0 22 22',
                'xmlns': 'http://www.w3.org/2000/svg'
            }, _createElement('path', {
                'd': 'M15.7825 15.7826L20.9999 21',
                'strokeWidth': '2',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            }), _createElement('path', {
                'd': 'M9.26087 17.5217C13.8232 17.5217 17.5217 13.8232 17.5217 9.26087C17.5217 4.69852 13.8232 1 9.26087 1C4.69852 1 1 4.69852 1 9.26087C1 13.8232 4.69852 17.5217 9.26087 17.5217Z',
                'strokeWidth': '1.9',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]