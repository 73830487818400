//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-504:_4256,_2096,_3640,_9124,_5412,_6504,_8204,_7952;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-504')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-504', "_4256,_2096,_3640,_9124,_5412,_6504,_8204,_7952");
        }
      }catch (ex) {
        console.error(ex);
      }